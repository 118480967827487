@import url(//fonts.googleapis.com/css?family=Open+Sans:400italic,300,600,400);

$sans-serif: "Open Sans", Helvetica, sans-serif;
$header-sans: "Zona Pro", Helvetica, sans-serif;

//Site-wide type styles

body {
  font-family: $sans-serif;
  font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $header-sans;
}

h1 {
  font-weight: 300;
  font-size: em(52);
  line-height: 1;
  color: $dark-gray;
  margin-bottom: .5em;
  margin-top: .5em;
}

h2 {
  font-size: em(36);
  line-height: 1.1;
  font-weight: 300;
  color: $secondary;
}

h3 {
  font-size: em(26);
  line-height: 1.3;
  font-weight: 600;
  color: $dark-gray;
}

h4 {
  font-size: em(25);
  line-height: 1.2;
  font-weight: 600;
  color: $primary;
}

h5 {
  font-size: em(24);
  line-height: 1.4;
  font-weight: 700;
  font-weight: 300;
  color: rgb(151, 151, 151);
}

h6 {
  font-size: em(14);
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  color: $primary;
  letter-spacing: em(3, 14);
}

p {
  font-size: em(18);
  line-height: 1.4;
  font-weight: normal;
}

blockquote {
  font-size: em(32);
  font-family: $header-sans;
  line-height: 1.3;
  font-weight: 300;
  text-align: center;
  &:before {
    content: '';
    display: block;
    margin: auto;
    width: 80%;
    max-width: 477px;
    max-height: 45px;
    height: auto;
    padding-bottom: 5%;
    background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2281374287-svg/assets/images/quote-divider.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: em(10, 32);
  }
}

cite {
  text-align: center;
  display: block;
  padding-top: em(14, 32);
  font-style: normal;
  span {
    font-weight: bold;
    font-size: em(22, 32);
    color: $primary;
    line-height: 1;
    display: block;
    text-align: center;
    padding-bottom: em(6, 32);
  }
  em {
    text-align: center;
    display: block;
    font-style: normal;
    color: $gray;
    text-transform: uppercase;
    font-size: em(14, 32);
    letter-spacing: em(3, 14);
    font-weight: bold;
  }
}

a, a:visited {
  color: $secondary;
  &:hover {
    text-decoration: none;
  }
}

ul, ol {
  margin: 0 0 em(30) 0;
  padding: 0 0 0 em(35);
  font-size: em(18);
  line-height: 1.4;
  li {
    margin: 0 0 1em;
    padding: 0;
  }
}

ul {
  list-style-type: square;
}