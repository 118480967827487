body.subscribe-bar-active {
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 80px;
  }
  @media (min-width: 992px) {
    padding-bottom: 93px;
  }
}

footer.subscribe-bar {
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
    height: 5.722222em !important;
    input[type="email"] {
      padding: 0.652em !important;
    }
    h3 {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }
  }
  @media (min-width: 768px) {
    width: 100%;
    height: em(94);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: em(20);
    border-top: 2px solid $gray;
    text-align: center;
    .inner {
      width: 100%;
      max-width: em(1024);
      height: em(55);
      position: relative;
      margin: auto;
      display: inline-block;
      h3 {
        display: inline-block !important;
        vertical-align: top;
        font-size: em(24);
        font-weight: 300;
        padding-top: em(12, 24);
        padding-bottom: em(12, 24);
        width: 50%;
        color: #979797;
        text-align: left;
        margin: 0;
      }
      label {
        display: none;
      }
      div[id^='hs_form_target_module'] {
        display: inline-block !important;
        width: 40%;
        padding-right: em(20);
      }
      form {
        border: 2px solid $gray;
        border-radius: 3px;
        padding: 6px;
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        > div:first-child {
          width: 70%;
          display: inline-block;
          vertical-align: middle;
          float: left;
          text-align: left;
        }
        input[type="email"] {
          font-size: em(17.77);
          line-height: (19 / 17.77);
          font-weight: 300;
          padding: 0.58em;
          padding-left: em(38, 17.77) !important;
          width: 100%;
          border: 0;
        }
        .inputs-list {
          display: none !important;
        }
        .input {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 6px);
          position: relative;
          &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-3209727980-svg/assets/images/blue-email.svg);
            background-size: contain;
            background-repeat: no-repeat;
            width: em(21);
            height: em(14.73);
            position: absolute;
            left: em(8);
            top: calc(50% - 0.409165em);
            bottom: calc(50% - 0.409165em);
          }
        }
        .hs_submit {
          display: inline-block;
          width: 30%;
          vertical-align: middle;
          float: right;
          input {
            font-size: em(11);
            width: 100%;
            padding: 1em 0 !important;
          }
        }
      }
    }
    button.close {
      position: absolute;
      right: 0;
      top: calc(50% - 0.47222em);
      border: 0;
      background-color: transparent;
      background-image: url("http://img.jillkonrath.com/hs-fs/hub/110248/file-3208152233-svg/assets/images/subscribe-close.svg");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      width: em(17);
      height: em(17);
      display: inline-block;
      vertical-align: middle;
    }
    &.closed {
      display: none;
    }
  }
}