figure.combo {
  width: 100%;
  position: relative;
  display: block;
  z-index: 1;
  background: $dark-gray;
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2337166542-svg/assets/images/btn-play.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    transition: 0.25s background-color ease-in-out;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &.video {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 766px) {
    max-height: 350px;
  }
}

.taglines {
  background-color: $light-gray;
  height: em(330);
  padding: em(45);
  position: relative;
  @media (max-width: 989px) {
    font-size: 11px;
  }
  @media (min-width: 990px) and (max-width: 1279px) {
    font-size: 13px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
  h2 {
    color: $dark-gray !important;
    position: absolute;
    bottom: em(45, 36);
    left: em(45, 36);
    margin: 0;
    line-height: 1.3 !important;
  }
}

a.video-link {
  display: block;
}

.resources-section {
  .sales-resources {
    background: $secondary;
    color: $white;
    padding: em(46);
    @media (max-width: 500px) {
      min-height: auto;
    }
    @media (max-width: 768px) {
      min-height: 43em;
    }
    @media (max-width: 1424px) {
      font-size: 12px;
    }
    @media (min-width: 1425px) {
      font-size: 18px;
    }
    h2 {
      text-transform: uppercase;
      color: $white;
      font-size: em(40);
      font-weight: 600;
      line-height: 1.09;
      letter-spacing: em(3, 44);
      margin-top: 0;
      margin-bottom: em(42, 44);
      @media (max-width: 975px) {
        font-size: em(32);
      }
    }
    hr {
      border: 0;
      border-bottom: 3px solid rgba(255, 255, 255, 0.17);
    }
    a.more {
      font-weight: bold;
      color: $white;
      font-size: em(14);
      background-color: transparent;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: em(2.62, 14);
      line-height: 1.07;
      padding: em(10, 14);
      border: 3px solid rgba(255, 255, 255, 0.17);
      border-radius: 3px;
      display: inline-block;
      margin-top: em(8, 14);
      transition: 0.25s all ease-in-out;
      &:hover {
        background-color: rgba(255, 255, 255, 0.17);
        border: 3px solid rgba(255, 255, 255, 0);
        text-decoration: none;
      }
    }
    nav {
      margin-left: em(-46);
      margin-right: em(-46);
      font-family: $header-sans;
      ul {
        list-style-type: none;
        padding: 0;
        li {
          line-height: em(62);
          a {
            color: $white;
            font-weight: 300;
            font-size: em(22);
            display: block;
            height: em(62, 22);
            line-height: em(62, 22);
            padding-left: em(52, 22);
            padding-right: em(15, 22);
            transition: 0.25s background-color ease-in-out;
            &:before {
              background-position: center center;
              background-repeat: no-repeat;
              background-size: contain;
              width: em(36);
              height: em(32);
              content: '';
              display: inline-block;
              transform: translateY(25%);
              margin-right: em(24);
            }
            &:hover {
              text-decoration: none;
              background-color: rgba(255, 255, 255, 0.17);
            }
          }
          &.blog a:before {
            background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2267186277-svg/assets/images/icon-blog.svg);
          }
          &.ebooks a:before {
            background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266628839-svg/assets/images/icon-ebooks.svg);
          }
          &.worksheets a:before {
            background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2267166512-svg/assets/images/icon-worksheets.svg);
          }
          &.videos a:before {
            background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2267155862-svg/assets/images/icon-videos.svg);
          }
          &.audio a:before {
            background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266202990-svg/assets/images/icon-audio.svg);
          }
          &.webinars a:before {
            background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266197890-svg/assets/images/icon-webinars.svg);
          }
        }
      }
    }
  }
  section.resource-list {
    background: $light-gray;
    padding: em(36) em(30);
    @media (min-width: 770px) {
      padding: em(36) em(43);
    }
    @media (max-width: 1424px) {
      font-size: 15px;
    }
    @media (min-width: 1425px) {
      font-size: 18px;
    }
    &.Ebooks, &.Videos, &.Webinars {
      background: $white;
    }
    h2 {
      margin: 0 0 em(13, 36) 0;
      @media (max-width: 990px) {
        font-size: em(28);
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        margin: 0 0 em(25) 0;
        padding: 0;
        &:nth-child(n+3) {
          @media (max-width: 1424px) {
            display: none;
          }
        }
        a {
          color: $dark-gray;
          font-size: em(16);
          line-height: 1.5;
          display: block;
          min-height: em(38, 16);
          @media (min-width: 770px) {
            padding-left: 3.67em;
            text-indent: -3.67em;
          }
          &:before {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: em(37, 16);
            height: em(37, 16);
            content: '';
            display: block;
            transform: translateY(50%);
            margin-bottom: 1em;
            margin-top: -1em;
            opacity: 0.25;
            @media (min-width: 770px) {
              display: inline-block;
              margin-right: em(24);
              margin-top: 0;
              margin-bottom: 0;
            }
          }
          &:hover {
            text-decoration: none;
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
    &.Blog ul li a:before {
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2262882493-svg/assets/images/icon-blog-d.svg?v=3);
    }
    &.Ebooks ul li a:before {
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266863439-svg/assets/images/icon-ebooks-d.svg?v=3);
    }
    &.Worksheets ul li a:before {
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2262872978-svg/assets/images/icon-worksheets-d.svg?v=3);
    }
    &.Videos ul li a:before {
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266570890-svg/assets/images/icon-videos-d.svg);
    }
    &.Audio ul li a:before {
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266565595-svg/assets/images/icon-audio-d.svg);
    }
    &.Webinars ul li a:before {
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266590705-svg/assets/images/icon-webinars-d.svg);
    }
  }
}

a.learn-more, a.read-more {
  font-size: em(14);
  line-height: 1;
  color: $primary;
  letter-spacing: em(3, 14);
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    color: $dark-gray;
  }
}

#subscribe-billboard {
  @media (max-width: 1279px) {
    font-size: 11px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
  h2 {
    color: $white;
    font-size: em(40);
    line-height: 1.3;
    text-align: center;
    width: 100%;
    max-width: 77.7rem;
    margin: auto;
  }
  .subscribe-form {
    padding-top: em(50);
    padding-bottom: em(50);
    h3 {
      display: none;
    }
    aside {
      font-weight: 400;
      font-family: $header-sans;
      font-size: em(14);
      line-height: 1.07;
      margin-top: em(10, 14);
      margin-bottom: 0;
      color: $form-gray;
    }
    form {
      border: 2px solid $gray;
      border-radius: 3px;
      padding: em(10);
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      label {
        display: none;
      }
      > div:first-child {
        @media (max-width: 767px) {
          width: 100%;
          display: inline-block;
          float: none;
        }
        @media (min-width: 768px) {
          width: 70%;
          display: inline-block;
          float: left;
        }
      }
    }
    .hs_email {
      .input {
        text-align: left;
        padding-left: em(15);
        margin-top: em(2.5);
      }
      .input:before {
        content: '';
        display: inline-block;
        width: em(34);
        height: em(24);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2272367390-svg/assets/images/email-input.svg);
      }
    }
    .hs_submit {
      @media (max-width: 767px) {
        width: 100%;
        display: inline-block;
        float: none;
        text-align: center;
      }
      @media (min-width: 768px) {
        width: 30%;
        display: inline-block;
        vertical-align: top;
        text-align: right;
        float: right;
      }
    }
    input[type="email"] {
      font-size: em(29);
      height: em(67, 29);
      line-height: 1.17;
      font-weight: 300;
      color: $white;
      width: calc(100% - 35px);
      padding: em(9);
      background: transparent;
      border: 0 solid transparent;
    }
    input[type="submit"] {
      height: em(67);
      width: 100%;
      max-width: 20rem;
      text-align: center;
      padding-left: em(5);
      padding-right: em(5);
      font-family: $header-sans;
      &:hover {
        background: transparent;
      }
    }
  }
  form:nth-child(n+2) {
    display: none;
  }
  h4 {
    font-size: em(26);
    line-height: 1.15;
    margin: 0 auto;
    color: $white;
    font-weight: 400;
  }
  p {
    margin: em(5, 16) 0 0 0;
    color: $form-gray;
    font-size: em(16);
  }
}

.main-content {
  width: 100%;
  hr {
    border-top: 3px solid $gray;
    margin-top: em(50);
    margin-bottom: em(50);
  }
  img {
    max-width: 100%;
    height: auto;
  }
  ul li, ol li {
    margin-bottom: 0 !important;
  }
}

.two-col-right {
  text-align: center;
  .site-header > div {
    border-bottom: 2px solid $gray;
  }
  .site-footer {
    border-top: 2px solid $gray;
  }
  .main-content {
    margin: auto !important;
    float: none !important;
    text-align: left;
    > div > div > div > div > .row-fluid,
    > div > .row-fluid {
      display: flex;
      align-items: stretch;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

  }
  .left-column {
    padding: 0 em(94) em(94) em(74);
    @media (max-width: 767px) {
      padding: 0 em(23) em(23) em(15);
    }
    @media (min-width: 768px) and (max-width: 990px) {
      padding: 0 em(47) em(47) em(37);
    }
  }
  .right-column {
    background-color: $light-gray;
    padding-bottom: em(25);
    h2 {
      margin-bottom: em(13, 40);
    }
    figure.combo {
      @media (max-width: 770px) {
        max-height: 500px;
      }
    }
    .hs_cos_wrapper:not(:first-child) {
      margin-left: em(50);
      margin-right: em(50);
      border-bottom: 3px solid $gray;
      padding-bottom: em(46);
      margin-bottom: em(36);
    }
    .hs_cos_wrapper:first-child {
      img {
        width: 100% !important;
        height: auto;
      }
    }
    .hs_cos_wrapper_type_blog_subscribe {
      label {
        display: none;
      }
      aside {
        font-family: $header-sans;
        color: $form-gray;
        font-size: em(14);
        line-height: 1.07;
        font-weight: 400;
        margin-top: em(17, 14);
      }
      form {
        background: $white;
        padding: em(12);
        border: 2px solid $gray;
        border-radius: 3px;
        .hs_email {
          display: inline-block;
          width: calc(100% - 160px);
          input[type="email"] {
            width: 100%;
            height: 5.3rem;
            padding: 2.3rem;
            line-height: 5.3rem;
            border: 0;
            background-color: transparent;
            font-size: 2.2rem;
            font-family: $header-sans;
            color: $dark-gray;
            font-weight: 300;
            position: relative;
            top: 0.1rem;
            @-moz-document url-prefix() {
              padding-top: 0;
              padding-bottom: 0;
            }
          }
        }
        .hs_submit {
          display: inline-block;
        }
        @media (max-width: 1024px) {
          .hs_email, .hs_submit {
            width: 100%;
            display: block;
            text-align: center;
          }
          .hs_email {
            margin-bottom: 1rem;
            input[type="email"] {
              border: 2px solid $light-gray;
              border-radius: 3px;
            }
          }
        }
        input[type="submit"] {
          background-color: $primary !important;
          border-color: $primary !important;
          font-size: 1.6rem !important;
          color: $white;
          padding: 1.5rem !important;
          &:hover {
            background-color: $white !important;
            color: $primary;
          }
        }
      }
    }
  }
}

.two-col-equal {
  text-align: center;
  .site-header > div {
    border-bottom: 2px solid $gray;
  }
  .site-footer {
    border-top: 2px solid $gray;
  }
  .main-content {
    text-align: left;
    > div > .row-fluid {
      display: flex;
      align-items: stretch;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  .left-column {
    padding: em(2) em(60) em(33);
    @media (max-width: 767px) {
      padding: 0 em(20) em(20);
      font-size: 14px;
    }
    @media (min-width: 768px) and (max-width: 990px) {
      font-size: 16px;
    }
  }
  .right-column {
    figure.combo {
      @media (max-width: 770px) {
        max-height: 500px;
      }
    }
  }
}

.two-col-resources {
  text-align: center;
  .site-header > div {
    border-bottom: 2px solid $gray;
  }
  .site-footer {
    border-top: 2px solid $gray;
  }
  .main-content {
    text-align: left;
    h2 {
      margin-bottom: 0;
    }
    h3 {
      margin-top: 0;
    }
    hr {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
  .left-column {
    padding: em(2) em(20) em(10) em(60);
  }
  .right-column {
    padding: em(2) em(60) em(10) em(20);
  }
  .full-width-column {
    padding: em(2) em(60) em(33);
  }
  .left-column, .right-column, .full-width-column {
    @media (max-width: 767px) {
      padding: 0 em(20) em(20);
      font-size: 14px;
    }
    @media (min-width: 768px) and (max-width: 990px) {
      font-size: 16px;
    }
  }
  .audio-list li a,
  .hs-rss-title a {
    color: darken($secondary, 10%) !important;
    &:hover {
      color: $secondary !important;
    }
  }
  .audio-list {
    list-style: none;
    margin: 0 0 2em;
    padding: 0;
    li {
      padding-left: 30px;
      line-height: 1.2;
      //margin-bottom:10px;
      background: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266565595-svg/assets/images/icon-audio-d.svg) no-repeat top left;
      background-size: 20px !important;
    }
  }
  .hs-rss-item {
    margin-bottom: 10px;
  }
  .left-column .hs-rss-title {
    padding-left: 30px;
    line-height: 1.2;
    margin-bottom: 10px;
    background: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2266570890-svg/assets/images/icon-videos-d.svg) no-repeat center left;
    background-size: 20px !important;
  }
  .right-column .hs-rss-title {
    padding-left: 30px;
    line-height: 1.2;
    margin-bottom: 10px;
    background: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2262882493-svg/assets/images/icon-blog-d.svg) no-repeat center left;
    background-size: 20px !important;
  }
}

.resource-feature {
  padding-bottom: 1.5em;
  a.rc-title {
    display: block;
    margin-bottom: .25em;
    @extend h3;
    color: darken($secondary, 10%) !important;
    &:hover {
      color: $secondary !important;
    }
  }
  .resource-image {
    margin-bottom: 1em;
    a {
      display: block;
    }
    @media (min-width: 768px) {
      img {
        padding-right: 1em;
      }
    }
  }
  .resource-content {
    br {
      display: none !important;
      position: absolute !important;
    }
    .rc-copy {
      font-size: .875em;
    }
  }
}

.testimonial-slider {
  font-size: 20.7px;
  position: relative;
  z-index: 3;
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 989px) {
    font-size: 16px;
  }
  @media (min-width: 990px) and (max-width: 1280px) {
    font-size: 18px;
  }
  text-align: center;
  padding: em(100, 20.7) em(20, 20.7);
  border-top: 2px solid $gray;
  blockquote {
    max-width: 1072px;
    margin: auto;
    p {
      font-weight: 300;
    }
  }
  &.negative {
    color: $white;
    background-color: $black;
    blockquote {
      color: $white;
    }
    cite em {
      color: $white;
    }
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.3;
    z-index: 4;
  }
  .carousel-inner {
    z-index: 5;
  }
  .carousel-control, .carousel-indicators {
    z-index: 6;
  }
}

.three-col {
  .left-column, .right-column {
    background: rgba(62, 63, 60, 0.3);
  }
  .middle-column {
    background: $light-gray;
  }
  .main-col {
    padding: em(40);
    @media (max-width: 1280px) {
      padding: em(20);
    }
  }
  .site-header > div {
    border-bottom: 2px solid $gray;
  }
  .site-footer {
    border-top: 2px solid $gray;
  }
}

.offer-box {
  background-color: $secondary;
  color: $white;
  padding: em(54);
  @media (max-width: 990px) {
    padding: em(20);
  }
  &.blue {
    background-color: $secondary;
  }
  &.red {
    background-color: $primary;
  }
  &.black {
    background-color: $dark-gray;
  }
  h2 {
    color: $white;
    margin-bottom: em(12);
  }
  p {
    font-size: em(20);
    color: $white;
    opacity: 0.7;
  }
  img {
    width: 100%;
    height: auto;
    max-width: 206px;
    display: inline-block;
    vertical-align: middle;
  }
  .left {
    text-align: right;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  .right {
    padding-left: em(33);
    .cta_button {
      font-size: em(19);
      font-family: $header-sans;
      line-height: 1.105;
      letter-spacing: em(3, 19);
      text-transform: uppercase;
      font-weight: bold;
      color: $white;
    }
  }
}

.subscribe-area {
  background-color: $light-gray;
  padding: em(54) !important;
  form {
    border: 2px solid $gray;
    border-radius: 3px;
    padding: em(10);
    background-color: $white;
    @media (max-width: 767px) {
      font-size: 10px !important;
    }
    @media (min-width: 768px) and (max-width: 1300px) {
      font-size: 11px !important;
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    label {
      display: none;
    }
    > div:first-child {
      width: 70%;
      display: inline-block;
      @media (max-width: 767px) {
        width: 100% !important;
      }
      @media (min-width: 768px) {
        float: left;
      }
    }
  }
  .inputs-list {
    display: none !important;
  }
  .hs_email {
    width: 100%;
    .input {
      text-align: left;
      padding-left: em(15);
      margin-top: em(2.5);
    }
    .input:before {
      content: '';
      display: inline-block;
      width: em(34);
      height: em(24);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2285643942-svg/assets/images/email-input-red.svg);
    }
  }
  aside {
    font-weight: 400;
    font-family: $header-sans;
    font-size: em(14);
    line-height: 1.07;
    margin-top: em(13, 14);
    margin-bottom: 0;
    color: $form-gray;
  }
  .hs_submit {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    text-align: right;
    @media (max-width: 767px) {
      width: 100% !important;
      text-align: center !important;
    }
    @media (min-width: 768px) {
      float: right;
    }
  }
  input[type="email"] {
    font-size: em(29);
    height: em(67, 29);
    line-height: 1.17;
    font-weight: 300;
    width: calc(100% - 35px);
    padding: em(9);
    background: transparent;
    border: 0 solid transparent;
  }
  input[type="submit"] {
    height: em(67);
    width: 100%;
    max-width: 20rem;
    text-align: center;
    padding-left: em(5);
    padding-right: em(5);
    font-family: $header-sans;
    border-color: $primary;
    background-color: $primary;
    &:hover {
      background: transparent;
      border-color: $primary;
      color: $primary;
    }
  }
  form:nth-child(n+2) {
    display: none;
  }
}

.speaking-complex {
  .top-area {
    .left-column {
      padding: em(45);
      background: $light-gray;
      @media (max-width: 767px) {
        padding: em(20);
      }
      p {
        margin-bottom: em(30);
      }
    }
    .right-column {
      background-color: $dark-gray;
    }
  }
  .mid-area {
    .left-column, .right-column {
      background-color: $dark-gray;
      h3 {
        margin-bottom: 0.3em;
      }
    }
  }
  .bot-area {
    .left-column, .right-column {
      img {
        width: 100%;
        height: auto;
        margin: -0.85em 0 0 0;
        max-width: none;
      }
      .pad-out {
        padding: em(45);
        padding-top: em(10);
      }
    }
    .left-column {
      background: $white;
    }
    .right-column {
      background: $light-gray;
      overflow: hidden;
    }
  }
  .site-header > div {
    border-bottom: 2px solid $gray;
  }
  .site-footer {
    border-top: 2px solid $gray;
  }
  figure.combo {
    @media (min-width: 770px) {
      min-height: em(360);
    }
  }
}

.client-list {
  text-align: center;
  .list-inner {
    max-width: 1212px;
    margin: auto;
    float: none !important;
    img {
      margin: em(12.5);
    }
    @media (max-width: 767px) {
      .span2 {
        width: 100% !important;
      }
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      .span2 {
        width: 33.3% !important;
      }
    }
  }
}

.book-listing-complex {
  .site-header > div {
    border-bottom: 2px solid $gray;
  }
  .billboard.medium {
    text-align: left;
    @media (min-width: 1280px) {}
  }
}

.book {
  border-bottom: 2px solid $gray;
  .book-info {
    padding-top: em(10);
    @media (max-width: 768px) {
      padding-left: em(20);
      padding-right: em(20);
    }
    @media (max-width: 1150px) {
      width: 100% !important;
      display: block;
    }
    .cover-area {
      text-align: center;
      @media (max-width: 1300px) {
        padding-right: em(20);
      }
      img {
        width: 100%;
        height: auto;
        max-width: 280px;
        margin: auto;
        display: inline-block;
      }
    }
    .summary-area {
      text-align: left;
      padding-right: em(75);
      @media (max-width: 990px) {
        padding-right: em(25);
      }
      summary {
        font-size: em(16);
      }
    }
  }
  .quote-area {
    background-color: $light-gray;
    padding: em(47) em(49);
    text-align: center;
    position: relative;
    @media (max-width: 1150px) {
      width: 100% !important;
      display: block;
    }
    @media (max-width: 1450px) {
      padding: em(20) em(20);
    }
    blockquote {
      font-size: em(20);
      &:before {
        background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2286642474-svg/assets/images/quote-divider-small.svg);
        padding-bottom: 12%;
      }
    }
    cite {
      margin-top: em(55);
      text-align: center;
      span {
        font-size: em(22);
        line-height: 1;
        font-weight: bold;
      }
      em {
        font-size: em(14);
        font-weight: bold;
        line-height: 1.28;
        letter-spacing: em(3, 14);
        color: rgba(62, 63, 60, 0.4);
      }
    }
  }
  &.even .quote-area {
    background-color: rgba(205, 211, 214, 0.5);
  }
}

.shops {
  list-style-type: none;
  margin: 0;
  padding: em(22) em(15);
  border-top: 2px solid $light-gray;
  text-align: center;
  li {
    margin: 0 em(12.5);
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 58px;
    img {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.book-detail-complex {
  .gray {
    background-color: $light-gray;
    padding-top: em(35);
    padding-bottom: em(35);
    @media (max-width: 768px) {
      padding: em(20);
    }
    .left {
      img {
        max-width: 367px;
        display: block;
        margin: 0 auto 1em;
      }
      text-align: center;
    }
    .row {
      max-width: 1320px;
      margin: auto;
      float: none;
      .left {
        @media (min-width: 768px) {
          padding-right: em(55);
        }
        img {
          width: 100%;
          height: auto;
          max-width: 503px;
        }
      }
      .right {
        @media (min-width: 768px) {
          padding-left: em(55);
          padding-right: em(20);
        }
        p, h2, h3, ul {
          margin-top: em(15);
          margin-bottom: em(15);
        }
      }
    }
  }
  .white {
    background-color: $white;
    border-top: 2px solid $gray;
    border-bottom: 2px solid $gray;
    padding: em(42) em(20);
    text-align: center;
    .shops {
      padding: 0;
      border-top: 0;
      margin-top: em(-10);
    }
  }
  .site-header > div {
    border-bottom: 2px solid $gray;
  }
  .site-footer {
    border-top: 2px solid $gray;
  }
}

.testimonials {
  .span4 {
    @media (max-width: 990px) {
      width: 100% !important;
      margin: auto;
      float: none;
    }
    &:nth-child(even) {
      .testimonial-single {
        background-color: $light-gray;
      }
    }
    &:nth-child(odd) {
      .testimonial-single {
        background-color: rgba(240, 242, 243, 0.3);
      }
    }
  }
}

.testimonial-single {
  text-align: center;
  padding: em(75) em(50);
  @media (max-width: 1400px) {
    padding: em(20);
  }
  blockquote {
    font-size: em(20);
    font-weight: 300;
    margin-bottom: em(38);
    &:before {
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2286642474-svg/assets/images/quote-divider-small.svg);
      padding-bottom: 12%;
    }
  }
  cite {
    span {
      font-size: em(22);
    }
    em {
      font-size: em(14);
    }
  }
}

.mj-landing-page {
  .main-nav {
    display: none;
  }
  .main-content {
    margin-top: -2px;
    @media (min-width: 1450px) {
      max-width: 1450px;
      margin: auto;
      float: none;
    }
    hr, hr.dotted-line {
      border: 0;
      border-top: 2px solid $gray;
      margin-top: em(30);
      margin-bottom: em(30);
    }
    .left-column {
      padding: em(50) em(20);
      @media (max-width: 768px) {
        padding: em(20);
      }
    }
    .right-column {
      padding: em(50) em(20);
      @media (max-width: 768px) {
        padding: em(20);
      }
    }
    > div > .row-fluid {
      display: flex;
      align-items: stretch;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  h3.form-title {
    @extend h2;
    font-size: em(40);
    margin-top: 0;
    margin-bottom: em(35, 40);
  }
  .site-header > div {
    border-bottom: 2px solid $gray;
  }
  .site-footer {
    border-top: 2px solid $gray;
  }
  input, .styled-select, textarea {
    margin-bottom: em(19);
    width: 100%;
    display: block;
  }
  label {
    margin-bottom: em(10);
    line-height: 2;
  }
  .hs_cos_wrapper_type_blog_subscribe {
    label {
      display: none;
    }
    aside {
      font-family: $header-sans;
      color: $form-gray;
      font-size: em(14);
      line-height: 1.07;
      font-weight: 400;
      margin-top: em(17, 14);
    }
    form {
      background: $white;
      padding: em(12);
      border: 2px solid $gray;
      border-radius: 3px;
      .hs_email {
        display: inline-block;
        width: calc(100% - 153px);
        input[type="email"] {
          width: 100%;
          height: 5.3rem;
          padding: 2.3rem;
          line-height: 5.3rem;
          border: 0;
          background-color: transparent;
          font-size: 2.2rem;
          font-family: $header-sans;
          color: $dark-gray;
          font-weight: 300;
          position: relative;
          top: 0.1rem;
        }
      }
      .hs_submit {
        display: inline-block;
      }
      @media (max-width: 1024px) {
        .hs_email, .hs_submit {
          width: 100%;
          display: block;
          text-align: center;
        }
        .hs_email {
          margin-bottom: 1rem;
          input[type="email"] {
            border: 2px solid $light-gray;
            border-radius: 3px;
          }
        }
      }
      input[type="submit"] {
        background-color: $primary !important;
        border-color: $primary !important;
        font-size: 1.6rem !important;
        color: $white;
        padding: 1.5rem !important;
        &:hover {
          background-color: $white !important;
          color: $primary;
        }
      }
    }
  }
  &.lp-left {
    .right-column {
      padding: 0 em(94) em(94) em(74);
      @media (max-width: 767px) {
        padding: 0 em(23) em(23) em(15);
      }
      @media (min-width: 768px) and (max-width: 990px) {
        padding: 0 em(47) em(47) em(37);
      }
      img {
        width: auto;
        max-width: 44%;
        float: right;
        margin: 0 0 em(30) em(30);
      }
    }
    .left-column {
      background-color: $light-gray;
      padding-bottom: em(25);
      h2 {
        margin-bottom: em(13, 40);
      }
      figure.combo {
        @media (max-width: 768px) {
          max-height: 500px;
        }
      }
      .hs_cos_wrapper:not(:first-child) {
        margin-left: em(50);
        margin-right: em(50);
        border-bottom: 3px solid $gray;
        padding-bottom: em(46);
        margin-bottom: em(36);
      }
      .hs_cos_wrapper:first-child {
        img {
          width: 100% !important;
          height: auto;
        }
      }
    }
  }
  &.lp-right {
    .left-column {
      padding: 0 em(94) em(94) em(74);
      @media (max-width: 767px) {
        padding: 0 em(23) em(23) em(15);
      }
      @media (min-width: 768px) and (max-width: 990px) {
        padding: 0 em(47) em(47) em(37);
      }
      img {
        width: auto;
        max-width: 44%;
        float: right;
        margin: 0 0 em(30) em(30);
      }
    }
    .right-column {
      background-color: $light-gray;
      padding-bottom: em(25);
      h2 {
        margin-bottom: em(13, 40);
      }
      figure.combo {
        @media (max-width: 768px) {
          max-height: 500px;
        }
      }
      .hs_cos_wrapper:not(:first-child) {
        margin-left: em(50);
        margin-right: em(50);
        border-bottom: 3px solid $gray;
        padding-bottom: em(46);
        margin-bottom: em(36);
      }
      .hs_cos_wrapper:first-child {
        img {
          width: 100% !important;
          height: auto;
        }
      }
    }
  }
}

.custom-search {
  .left-column {}
  .right-column {
    padding: em(50) em(20);
    @media (max-width: 768px) {
      padding: em(20);
    }
  }
}

.widget-type-raw_jinja .col-sm-5 {
  @media (min-width: 768px) {
    padding-left: em(30);
  }
}

video {
  width: 100% !important;
  height: auto !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  &.large-video {
    padding-bottom: 76.25%;
  }
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}