.modal-nest {
  &:not(.shown) {
    display: none;
  }
  &.shown {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(205, 211, 214, .86);
    text-align: center;
    z-index: 22;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__container {
    position: relative;
    width: 90%;
    height: auto;
    max-width: em(725);
  }
}

.subscribe-modal {
  box-shadow: 0 em(2) em(3) rgba(0, 0, 0, .19);
  border-radius: em(4);
  background-color: #d34c39;
  position: relative;
  &__inner {
    padding: em(45) em(60);
    width: 100%;
    overflow-y: scroll;
  }
  &__title {
    color: white;
    font-family: $header-sans;
    font-size: em(46);
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: em(31, 46);
  }
  &__description {
    margin-bottom: 0;
    color: white;
    font-family: $header-sans;
    font-size: em(22);
    font-weight: 400;
    line-height: (30.8 / 22);
  }
  &__cta {
    margin-top: em(33);
    .cta-btn {
      color: white;
      font-family: $header-sans;
      font-size: em(19);
      font-weight: 700;
      letter-spacing: em(3, 19);
      text-transform: uppercase;
      padding: em(15.5, 19) em(30, 19);
      border-radius: 3px !important;
      background-color: #269ccc;
      &:hover {
        text-decoration: none;
        color: white;
        background-color: darken(#269ccc, 10%);
      }
    }
  }
  &__close {
    position: absolute;
    top: em(18);
    right: em(16);
    padding: 0;
    border: 0;
    background: transparent;
    opacity: 1;
    transition: .25s opacity ease-in-out;
    &,
    svg {
      width: em(17);
      height: em(17);
    }
    &:hover {
      opacity: .8;
    }
  }
}
