.homepage-book {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  @media (min-width: 992px) {
    align-items: stretch;
  }
  * {
    box-sizing: inherit;
  }
  &__left {
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-basis: 66.6666%;
      flex-grow: 1;
    }
  }
  &__top,
  &__bottom {
    flex-grow: 1;
  }
  &__top {
    background-color: #269ccc;
    color: white;
    display: flex;
    position: relative;
    @media (max-width: 767px) {
      flex-direction: column;
      text-align: center;
      &:before {
        width: em(300);
        height: em(300);
        top: em(30);
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    @media (min-width: 768px) {
      align-items: center;
      &:before {
        width: 33.333%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }
    @media (max-width: 767px) {
      padding: em(16) em(50);
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      padding: em(16) em(50);
    }
    @media (min-width: 1200px) {
      padding: em(16) em(100);
    }
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      background-image: radial-gradient(ellipse at center, white 0%, #269ccc 70%);
      opacity: .67;
      z-index: 1;
    }
    * {
      color: white;
      position: relative;
      z-index: 2;
    }
    h5,
    h2,
    p {
      font-weight: 400;
    }
    h5 {
      margin-top: 0;
      margin-bottom: em(15, 25);
    }
    h2 {
      margin-top: 0;
      margin-bottom: em(8, 41);
    }
    p {
      margin-top: 0;
      margin-bottom: em(23, 16);
      line-height: 1.5;
      font-family: $header-sans;
    }
  }
  &__book {
    &-image {
      text-align: center;
      flex-shrink: 0;
      @media (min-width: 768px) and (max-width: 1199px) {
        flex-basis: em(180);
      }
      @media (min-width: 1200px) {
        flex-basis: 26%;
      }
      img {
        margin: auto;
        height: auto;
        @media (max-width: 767px) {
          width: em(180);
        }
        @media (min-width: 768px) {
          width: 100%;
        }
      }
    }
    &-content {
      flex-grow: 1;
      text-align: left;
      padding: em(25) 0;
      @media (min-width: 768px) {
        margin-left: em(79);
        padding: em(25) em(10) em(25) 0;
      }
      .hs-cta-wrapper {
        > span {
          display: inline-block;
        }
      }
      .cta-btn {
        display: inline-block;
        font-family: $header-sans;
        font-size: em(16);
        padding: em(11, 16) em(18, 16);
        letter-spacing: em(3, 16);
      }
    }
  }
  &__bottom {
    display: flex;
    align-items: stretch;
  }
  &__about {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1279px) {
      padding: em(45);
      width: 100%;
    }
    @media (min-width: 1280px) {
      padding: em(10) em(45);
      width: 66.666%;
    }
    &-content {
      width: 100%;
      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }
  }
  &__logo {
    position: relative;
    @media (max-width: 1279px) {
      display: none;
    }
    @media (min-width: 1280px) {
      width: 33.333%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__photo {
    position: relative;
    text-align: center;
    overflow: hidden;
    @media (max-width: 991px) {
      width: 100%;
      height: 25em;
    }
    @media (min-width: 992px) {
      flex-basis: 33.333%;
    }
    img {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      width: auto;
      left: 50%;
      margin: auto;
      transform: translateX(-50%);
    }
  }
}
