.resources-primary {
  .main-content,
  .resources-section {
    > div > .row-fluid {
      display: flex;
      align-items: stretch;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  .sales-resources {
    h2 {
      font-size: em(16);
      font-weight: bold;
      margin-bottom: em(42, 16);
      color: $gray;
    }
    li {
      font-weight: 300;
      a:before {
        font-size: 0.8em;
      }
    }
  }
}

.subscribe-area.additional-header {
    text-align:center;
    > div {
      max-width:em(715);
      margin:auto;
    }
    form {
      font-size:0.9em;
      input:focus {
        border-color:transparent;
      }
    }
    h3 {
      text-align:center;
      text-transform:uppercase;
      font-weight:bold;
      font-size:em(36);
      line-height: (62 / 36);
      color:$primary;
      margin-top:0;
      span {
        display:block;
        text-align:center;
        font-weight:normal;
        font-size:em(22,36);
        line-height:(30.8 / 22);
        color:#979797;
        text-transform:none;
      }
    }
  }

.header-left {
  background-color: $light-gray;
  @media (max-width: 767px) {
    font-size: 13px;
  }
  .cta-btn {
    padding-top: em(15, 19);
    padding-bottom: em(15, 19);
  }
  .white {
    padding: em(51) em(54);
    background-color: $white;
    h1 {
      margin-top: 0;
      margin-bottom: em(6, 62);
    }
    h5 {
      &:first-child {
        margin-bottom: em(30, 24);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .gray {
    padding: em(35) em(54);
    background-color: $light-gray;
    h3 {
      padding-right: em(15);
    }
    h3, p {
      width: 49%;
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }
  }
}

@media (max-width: 991px) {
  .header-left, .video-no-gutter {
    width: 100% !important;
    display: block;
  }
}

.video-nest {
  text-align: center;
  white-space: nowrap;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
  .inner {
    display: inline-block;
    vertical-align: middle;
    width:100%;
  }
}

.resource-grid {
  margin-left: -10px !important;
  margin-right: -10px !important;
  list-style-type: none;
}

.resource-nest {
  padding: em(60) em(65) !important;
  @media (max-width: 767px) {
    font-size: 13px;
  }
  &.white {
    background-color: $white;
  }
  &.gray {
    background-color: $light-gray;
  }
}

.resource-content {
  @media (min-width: 768px) {
    width: 74.35897% + 2.5641%;
    margin-left: 0 !important;
  }
  h2 {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: em(49, 36);
  }
  h4 {
    text-align: center;
    text-transform: uppercase;
    font-size: em(19);
    margin-top: em(40, 19);
    margin-bottom: em(40, 19);
    border-top: 4px solid $gray;
    padding-top: em(26, 19);
    line-height: 1;
    font-weight: bold;
    a {
      color: $primary;
      position: relative;
      padding-right: em(45, 19);
      letter-spacing: em(3, 19);
      font-weight: bold;
      @media (max-width: 767px) {
        padding-right: 0;
      }
      @media (max-width: 991px) {
        display: block;
      }
      &:after {
        @media (max-width: 767px) {
          display: none;
        }
        content: '';
        display: inline-block;
        vertical-align: middle;
        background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-3216121978-svg/assets/images/arrow-right-red.svg);
        width: em(22, 19);
        height: em(15, 19);
        position: absolute;
        right: 0;
        top: calc(50% - .3947em);
      }
    }
  }
}

.video-no-gutter {
  background-color: #3E3F3C;
}

.individual-resource {
  padding-bottom: em(36);
  &:not(.has-image) {
    main {
      width: 100%;
    }
  }
  &.has-image {
    aside, main {
      display: inline-block;
      vertical-align: top;
    }
    aside {
      width: em(95);
    }
    figure {
      width: 81%;
      height: 0;
      padding-bottom: 81%;
      border-radius: 50%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    main {
      width: calc(100% - 5.55em);
      > a:hover h3 {
        color:$secondary;
      }
    }
  }
  summary > a:hover {
    color:darken($secondary, 10%);
  }
  a, a h3 {
    transition: 0.25s color ease-in-out;
  }
  h3 {
    font-size: em(23);
    line-height: (30 / 23);
    font-weight: 300;
    margin-bottom: em(6, 23);
    margin-top: 0;
  }
  p {
    margin: 0;
    font-size: em(18);
    color: #999;
    font-weight: normal;
  }
}

.subscribe-simple {
  text-align: center;
  padding: em(60) em(20) em(75) !important;
  @media (max-width: 767px) {
    font-size: 12px;
  }
  h3 {
    margin-top: 0;
    margin-bottom: em(20, 26);
    font-weight: 600;
  }
  label {
    display: none;
  }
  form {
    position: relative;
    width: 100%;
    height: em(79);
    max-width: em(805);
    margin: auto;
    border: 2px solid $gray;
    border-radius: 3px;
    input[type="email"] {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      border: 0;
      padding-right: em(170, 22);
      left: 0;
      z-index: 3;
      &:focus {
        border: 0;
      }
    }
    input[type="submit"] {
      position: absolute;
      right: em(10.5, 16);
      top: em(10.5, 16);
      bottom: em(10.5, 16);
      width: em(148, 16);
      height: em(56, 16);
      z-index: 4;
      font-size: em(16);
      font-weight: bold;
      background-color: $primary;
      border: 0;
      color: white;
      text-transform: uppercase;
      text-align: center;
      padding: 0;
      margin: 0;
      &:hover, &:focus {
        background-color: darken($primary, 5%);
      }
    }
  }
}

.resources-primary {
  .resources-section .sales-resources {
    min-height: 0 !important;
  }
}
