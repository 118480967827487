.home {
  .homepage-brand {
    background: $white;
    img {
      width: 100% !important;
      height: auto;
    }
    @media (max-width: 1280px) {
      display: none !important;
    }
  }
  .billboard .img-overlay {
	  @media(min-width:770px) {
		  background-attachment:fixed;
	  }
  }
  .bio {
    background: $white;
    padding: em(45);
    @media (max-width: 1280px) {
      width: 100%;
      font-size: 16px;
    }
    @media (min-width: 1281px) and (max-width: 1349px) {
      font-size: 16px;
    }
    @media (min-width: 1350px) {
      font-size: 18px;
    }
    p {
      font-size: em(16);
    }
    a.learn-more {
      font-size: em(14);
      font-family: $header-sans;
      line-height: 1;
      color: $primary;
      letter-spacing: em(3, 14);
      text-transform: uppercase;
      font-weight: bold;
      &:hover {
        color: $dark-gray;
      }
    }
  }
  @media (max-width: 767px) {
    .photo-left {
      display: none;
    }
    .taglines {
      width: 100% !important;
    }
  }
}

.bio-section {
  background: $dark-gray;
  position:relative !important;
  z-index:5;
}