.mj-blog {
  @extend .two-col-right;
  .hs-blog-social-share .hs-blog-social-share-list .hs-blog-social-share-item .twitter-share-button {
    width: 90px !important;
    position: relative;
    top: -2px !important;
  }
  .hs-blog-social-share .hs-blog-social-share-list .hs-blog-social-share-item-google-plus iframe {
    position: relative !important;
    top: -2px !important;
  }
  @media (max-width: 767px) {
    .span8.right {
      padding-left: 1.5rem !important;
    }
  }
  .blog-pagination {
    width: 100%;
    display: block;
    .pager {
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: center;
      li {
        //width: calc(50% - 4.444em);
        display: inline-block;
        vertical-align: top;
        padding: em(40);
        text-align: center;
        @media (max-width: 767px) {
          width: 100%;
          padding: em(15) !important;
        }
        a {
          font-family: $header-sans;
          font-size: em(19);
          font-weight: bold;
          line-height: 1.105;
          letter-spacing: em(3, 19);
          text-transform: uppercase;
          &.left-arrow {
            &:before {
              width: em(56);
              height: em(37);
              content: '';
              display: inline-block;
              background-size: contain;
              background-position: left center;
              background-repeat: no-repeat;
              background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2303469820-svg/assets/images/arrow-left-circle.svg);
              position: relative;
              top: em(10);
            }
          }
          &.right-arrow {
            &:after {
              width: em(56);
              height: em(37);
              content: '';
              display: inline-block;
              background-size: contain;
              background-position: right center;
              background-repeat: no-repeat;
              background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2302476576-svg/assets/images/arrow-right-circle.svg);
              position: relative;
              top: em(10);
            }
          }
        }
      }
    }
  }
  .main-content {
    max-width: none;
    h1 {
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
    h1 a {
      color: $dark-gray;
      display: block;
    }
    h2 {
      font-size: em(36);
      line-height: 1.1;
      color: $secondary;
      font-weight: 300;
    }
    hr {
      margin-top: 2.6rem;
      margin-bottom: 4rem;
    }
    .hs_cos_wrapper_type_blog_subscribe {
      display: block;
      background: $light-gray;
      padding: em(45) em(84);
      margin-top: em(30);
      margin-bottom: em(30);
      h3 {
        margin-top: 0;
      }
      aside {
        font-weight: 400;
        font-family: $header-sans;
        font-size: em(14);
        line-height: 1.07;
        margin-top: em(10, 14);
        margin-bottom: 0;
        color: $form-gray;
      }
      form {
        border: 2px solid $gray;
        background: $white;
        border-radius: 3px;
        padding: em(10);
        label {
          display: none;
        }
      }
      .hs_email {
        width: 70%;
        display: inline-block;
        .input {
          text-align: left;
          padding-left: em(15);
          margin-top: em(2.5);
        }
        .input:before {
          content: '';
          display: inline-block;
          width: em(34);
          height: em(24);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left center;
          background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2272367390-svg/assets/images/email-input.svg);
        }
      }
      .hs_submit {
        width: 30%;
        display: inline-block;
        vertical-align: top;
        text-align: right;
      }
      input[type="email"] {
        font-size: em(29);
        height: em(67, 29);
        line-height: 1.17;
        font-weight: 300;
        width: calc(100% - 35px);
        padding: em(9);
        background: transparent;
        border: 0 solid transparent;
      }
      input[type="submit"] {
        height: em(67);
        width: 100%;
        max-width: 20rem;
        text-align: center;
        padding-left: em(5);
        padding-right: em(5);
        font-family: $header-sans;
        &:hover {
          background: transparent;
        }
      }
    }
  }
  li.hs-blog-social-share-item.hs-blog-social-share-item-facebook {
    margin-top: -1rem;
  }
  .right-column {
    h3 {
      color: $secondary;
      font-family: $header-sans;
      font-weight: 300;
      font-size: em(35);
    }
    .row-fluid-wrapper:not(:first-child) {
      padding: em(34) em(54);
      &:after {
        content: '';
        border-bottom: 3px solid $gray;
        height: 3px;
        width: 100%;
        text-align: center;
        margin: auto;
        display: block;
        position: relative;
        top: em(34);
      }
    }
  }
  span.topics {
    color: $primary;
    font-family: $header-sans;
    font-size: em(14);
    letter-spacing: em(3, 14);
    font-weight: 600;
    text-transform: uppercase;
    margin-top: em(60);
    display: block;
    a {
      color: $primary;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .widget-type-post_listing {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        padding: em(12.5) 0;
        margin: 0;
        line-height: 1.18;
        a {
          display: block;
          font-family: $header-sans;
          font-size: em(22);
          font-weight: 400;
          color: $dark-gray;
          transition: 0.125s all ease-in-out;
          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
  .widget-type-post_filter {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        padding: em(10) 0;
        margin: 0;
        a {
          display: block;
          font-family: $header-sans;
          font-size: em(22);
          color: $dark-gray;
          line-height: em(26, 22);
          transition: 0.125s all ease-in-out;
          span.count {
            background-color: $gray;
            color: rgb(145, 147, 139);
            font-size: em(14, 22);
            font-weight: bold;
            padding: 0 em(5);
            min-width: em(28, 14);
            height: em(24, 14);
            display: inline-block;
            border-radius: 3px;
            line-height: em(26, 14);
            text-align: center;
            vertical-align: middle;
            transition: 0.125s all ease-in-out;
          }
          &:hover {
            color: $secondary;
            span.count {
              background-color: $secondary;
              color: $white;
            }
          }
        }
      }
    }
  }
  &.blog-listing {
    .blog-section {
      position: relative;
    }
    .blog-title a {
      display: block;
    }
    a.featured {
      width: 100%;
      display: block;
      border: 1px solid #ddd;
      img {
        position: relative !important;
        width: 100%;
        height: auto;
      }
      &:hover {
        border-color: $primary;
      }
    }
    .preview-content {
      img,
      .video-container,
      iframe,
      video {
        display: none !important;
        visibility: hidden;
      }
    }
    .read-more {
      font-size: em(19);
      display: block;
      margin-top: em(20, 19);
    }
    .post-item {
      padding-bottom: em(27);
      .left {
        padding-top: em(35);
      }
      .right {
        padding-left: em(38);
      }
    }
  }
  &.blog-post {
    .section.post-footer {
      background-color: $light-gray;
      padding: em(43) em(130);
      @media (max-width: 768px) {
        padding: em(43);
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }
    }
    #comments-listing {
      .comment {
        border-bottom: 3px solid $gray;
        margin-top: 0 !important;
        padding-top: 0 !important;
        &.depth-1 {
          padding-left: 0 !important;
          margin-left: 2em;
        }
        &.depth-2 {
          padding-left: 0 !important;
          margin-left: 4em;
        }
        &.depth-3 {
          padding-left: 0 !important;
          margin-left: 6em;
        }
        &.depth-4 {
          padding-left: 0 !important;
          margin-left: 8em;
        }
        &.depth-5 {
          padding-left: 0 !important;
          margin-left: 10em;
        }
        .comment-from {
          h4 {
            font-family: $header-sans;
            font-size: em(22);
            font-weight: 600;
            color: $dark-gray !important;
            a {
              color: $dark-gray;
            }
          }
        }
        .comment-date {
          color: rgb(151, 151, 151);
          font-size: em(16);
          font-weight: 400;
          text-transform: uppercase;
          font-family: $header-sans;
          @media (max-width: 768px) {
            display: block;
            padding-bottom: 1.5rem;
            margin-top: -2rem;
          }
        }
        .comment-body {
          font-family: $sans-serif;
          color: $dark-gray;
          font-size: em(16);
          line-height: 1.7;
          margin-top: em(-25, 16);
        }
        button.comment-reply-to {
          color: rgb(62, 63, 60);
          border: 3px solid rgb(62, 63, 60);
          font-size: em(14);
          padding: em(11, 14) em(11, 14) em(11, 14) em(28, 14);
          display: inline-block;
          opacity: 0.5;
          border-radius: 3px;
          text-transform: uppercase;
          margin-bottom: 3rem;
          transition: 0.125s all ease-in-out;
          em {
            font-style: normal;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    #comment-form {
      margin-top: em(38);
      label {
        display: none;
      }
      form input[type="submit"] {
        margin-left: em(15);
        margin-right: em(15);
        margin-top: em(5);
      }
      textarea {
        height: em(148);
      }
      .hs-form-field {
        margin-bottom: em(14);
        input, textarea {
          width: 100%;
          display: block;
        }
        &.hs_firstName, &.hs_lastName {
          @extend .col-sm-6;
        }
        &.hs_email, &.hs_website, &.hs_comment {
          @extend .col-sm-12;
        }
      }
    }
  }
}

.blog-section {
  max-width: 750px;
  margin: 0 auto;
}

// Share Fix
.hs-blog-social-share {
  margin-top: 1em;
  height: auto !important;
}

.hs-blog-social-share-list {
  margin-bottom: 0;
  @include clearfix;
  .hs-blog-social-share-item {
    float: none !important;
    display: inline-block;
    margin: 5px 0;
  }
  .hs-blog-social-share-item-linkedin .IN-widget,
  .hs-blog-social-share-item-facebook div {
    position: relative;
  }
  .hs-blog-social-share-item-linkedin .IN-widget {
    top: -2px;
  }
  .hs-blog-social-share-item-facebook div {
    top: -8px;
    margin: 0;
  }
}