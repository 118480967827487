.cta-btn {
  font-size: em(19);
  line-height: 1.105;
  letter-spacing: em(3, 21);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border-width: 3px;
  border-radius: 3px;
  padding: em(7.5, 19) em(15, 19);
  border-style: solid;
  background-color: transparent;
  transition: 0.25s all ease-in-out;
  @media (max-width: 1024px) {
    display: block;
    overflow-x: visible;
  }
  &.primary {
    color: $primary;
    border-color: $primary;
    &:hover {
      background: $primary;
      color: $white;
    }
  }
  &.secondary {
    color: $secondary;
    border-color: $secondary;
    &:hover {
      background: $secondary;
      color: $white;
    }
  }
  &.gray {
    border-color: $gray;
    color: rgb(142, 148, 152);
    &:hover {
      border-color: $dark-gray;
      color: $dark-gray;
    }
  }
  &.negative {
    background-color: transparent;
    border-color: $white;
    color: $white;
    &:hover {
      background: $white;
      color: $primary;
    }
  }
}

.widget-type-raw_jinja .cta-btn {
  display: block;
}

.sidebar-offer {
  background: $secondary;
  color: $white;
  padding: em(24) em(48) 0;
  h3 {
    font-family: $header-sans;
    font-size: em(35.7);
    line-height: 1.31;
    font-weight: 300;
    color: $white !important;
  }
  h4 {
    font-family: $header-sans;
    font-size: em(22.7);
    font-weight: 400;
    line-height: 1.14;
    color: $white;
    opacity: 0.7;
    margin-bottom: em(29, 22.7);
    margin-top: em(-17);
  }
  .hs-cta-wrapper {
    display: block;
    margin-bottom: em(69);
  }
  .hs-cta-wrapper a {
    @extend .cta-btn;
    @extend .cta-btn.negative;
    font-family: $header-sans;
    font-weight: 600;
    &:hover {
      color: $secondary !important;
    }
  }
}