.billboard {
  position: relative;
  z-index: 2;
  background: $black;
  color: $white;
  &.medium {
    padding-left: em(105);
    padding-right: em(105);
    text-align: center;
    @media (max-width: 767px) {
      height: auto;
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .input:before {
        display: none !important;
      }
      input[type="email"] {
        width: 100% !important;
      }
    }
    @media (min-width: 768px) and (max-width: 1279px) {
      height: em(782);
    }
    @media (min-width: 1280px) {
      height: em(687);
    }
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    }
    .inner {
      text-align: center;
    }
    .img-overlay {
      opacity: 0.7;
    }
  }
  &.large {
    padding-left: em(100);
    padding-right: em(100);
    @media (max-width: 767px) {
      height: 56.8rem;
      font-size: 11px;
      text-align: center;
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em;
      }
      .inner {
        text-align: center;
        margin: auto;
        h1 {
          margin: auto;
        }
      }
    }
    @media (min-width: 768px) and (max-width: 1279px) {
      height: 48.7rem;
      font-size: 12px;
    }
    @media (min-width: 1280px) {
      height: calc(100vh - 88px);
      min-height: 75rem;
      padding-top: em(118);
      font-size: 18px;
      margin-bottom: -62px;
    }
    .img-overlay {
      opacity: 0.5;
    }
  }
  &.engagements {
    padding: em(97) em(71) em(55);
    @media (max-width: 767px) {
      padding: em(45) em(20) em(27.5);
    }
    h1 {
      font-size: em(52);
      margin-top: em(14);
      @media (max-width: 767px) {
        font-size: em(40);
      }
    }
    h6 {
      color: $white;
      text-align: center;
      margin-bottom: em(35);
    }
    .recent-speaking-engagements {
      width: 100%;
      //max-width: 805px;
      text-align: center;
      margin: auto;
      float: none !important;
      z-index: 4;
      position: relative;
      img {
        margin: em(13);
      }
      @media (max-width: 767px) {
        display: none;
      }
      @media (min-width: 1280px) {
        margin-top: em(236);
      }
    }
    @media (max-width: 1279px) {
      height: auto;
    }
    @media (min-width: 1280px) {
      height: em(700);
    }
  }
  &.vertical-center:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
  .img-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    z-index: 3;
  }
  .inner {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 4;
  }
  h1 {
    color: $white;
  }
  &.w-logobar {
    @media (max-width:767px) {
      white-space:nowrap;
      .logo-bar {
        white-space: normal;
        img {
          height:2rem !important;
          width:auto;
        }
      }
    }
    @media (min-width: 1280px) {
      min-height: 0 !important;
      margin-bottom: 0 !important;
    }
    .img-overlay {
      opacity:1 !important;
      background-position: center top !important;
    }
    .hs-cta-wrapper {
      margin-top:em(23);
    }
    h1 {
      line-height:(73 / 54);
      font-weight:300;
    }
    .hs-cta-wrapper a {
      @extend .cta-btn;
      @extend .cta-btn.primary;
      font-family: $header-sans;
      font-weight: 600;
      font-size:em(19);
      padding-top:em(10,19);
      padding-bottom:em(10,19);
      @media (max-width:767px) {
        color:white !important;
      }
    }
  }
  .logo-bar {
    position:absolute;
    width:100%;
    background-color:white;
    bottom:0;
    left:0;
    right:0;
    padding:em(20) em(30);
    z-index:5;
    overflow:hidden;
    nav {
      ul {
        list-style-type:none;
        text-align:center;
        margin:0;
        padding:0;
        @media (min-width:992px) {
          display:flex;
          justify-content: space-between;
          width:100%;
        }
        li {
          display:inline-block;
          margin:0;
          @media (max-width:991px) {
            padding-top:em(10);
            padding-bottom:em(10);
            padding:em(10) em(40);
            img {
              height:3.8rem;
              width:auto;
            }
          }
          &:first-child {
            padding-left:em(20);
          }
          &:last-child {
            padding-right:em(20);
          }
        }
      }
    }
  }
}
.home .billboard.large.w-logobar {
  .img-overlay {
    opacity:0.5 !important;
  }
}