//General form styles
form {
  label {
    font-size: em(18);
    font-weight: 600;
    line-height: 1.333;
  }
  input {
    font-family: $header-sans;
    &:focus {
      outline: none !important;
    }
  }
  ul {
    list-style-type: none;
  }
  input[type="text"], input[type="password"], input[type="email"] {
    font-size: em(22);
    line-height: 1.09;
    padding: em(15, 22);
    font-weight: 300;
    color: $dark-gray;
    border: 1px solid $gray;
    border-radius: 3px;
    &:focus {
      border: 1px solid $dark-gray;
    }
  }
  input[type="submit"] {
    border: 3px solid $secondary;
    border-radius: 3px;
    background: $secondary;
    color: $white;
    text-transform: uppercase;
    font-size: em(19);
    line-height: 1.105;
    padding: em(15, 19) em(50, 19);
    letter-spacing: em(3, 19);
    transition: 0.25s all ease-in-out;
    font-weight: bold;
    &:hover {
      background: $white;
      color: $secondary;
    }
  }
  .styled-select {
    //Indicator arrows
    background: $white url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2283763683-svg/assets/images/select-caret.svg) no-repeat 96% 50%;
    height: 50px;
    overflow: hidden;
    width: 100%;
    select {
      background: transparent;
      border: none;
      font-size: em(22);
      line-height: 1.09;
      font-weight: 300;
      color: $dark-gray;
      border: 1px solid $gray;
      border-radius: 3px;
      height: 50px;
      padding: 13px;
      /* If you add too much padding here, the options won't show in IE */
      width: 100%;
      -webkit-appearance: none;
    }
  }
  textarea {
    font-size: em(22);
    line-height: 1.09;
    padding: em(15, 22);
    color: $dark-gray;
    border: 1px solid $gray;
    border-radius: 3px;
  }
  .checkbox, .follow-up-field, .hs-form-booleancheckbox {
    label {
      @include clearfix;
      text-transform: none;
    }
    input {
      width: auto !important;
      padding: 0 !important;
    }
  }
}

.inputs-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hs-form fieldset {
  max-width: none !important;
  .hs-form-field {
    float: none !important;
  }
  .input {
    margin-right: 0 !important;
    margin-bottom: 1em !important;
  }
  input, textarea {
    width: 100% !important;
  }
  .hs-form-booleancheckbox {
    margin-bottom: 1em;
    input {
      width: auto !important;
      margin-right: 10px !important;
    }
  }
  select {
    background: $white url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2283763683-svg/assets/images/select-caret.svg) no-repeat 96% 50%;
    height: 50px;
    font-size: em(22);
    line-height: 1.09;
    font-weight: 300;
    color: $dark-gray;
    border: 1px solid $gray;
    border-radius: 3px;
    height: 56px;
    padding: 13px;
    /* If you add too much padding here, the options won't show in IE */
    width: 100%;
    -webkit-appearance: none;
  }
}

.hs-form fieldset.form-columns-2 .hs-form-field {
  width: 100% !important;
  @media (min-width: 540px) {
    width: 50% !important;
    &:first-child {
      float: left !important;
      .input {
        margin-right: 8px !important;
      }
    }
    &:last-child {
      float: right !important;
      label,
      .input {
        margin-left: 8px;
      }
    }
  }
}

form .hs-form-booleancheckbox input {
  margin-right: 10px !important;
  display: inline-block !important;
}

//Set color of input placeholder text
::-webkit-input-placeholder {
  color: $gray;
}

:-moz-placeholder {
  color: $gray;
  opacity: 1;
}

::-moz-placeholder {
  color: $gray;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $gray;
}