.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  background: rgba(211, 76, 57, 0.9);
  color: $white;
  font-size: em(22);
  overflow-y: auto;
  text-align: center;
  .overlay-close {
    width: 35px;
    height: 35px;
    position: absolute;
    margin: auto;
    bottom: 34px;
    overflow: hidden;
    border: none;
    background: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2277839871-svg/assets/images/close-menu.svg) no-repeat center center;
    background-size: contain;
    text-indent: 200%;
    color: transparent;
    outline: none;
    z-index: 100;
  }
  nav {
    position: relative;
    top: 3.2rem;
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin: auto;
    ul {
      text-align: center;
      margin: 0 auto;
      list-style-type: none;
      padding: 0;
      display: block !important;
      float: none !important;
      font-family: $header-sans;
      i {
        display: none !important;
      }
      .hs-menu-children-wrapper {
        display: none !important;
      }
      & > li {
        text-align: center;
        margin: 0;
        padding: 0;
        display: block !important;
        float: none !important;
        margin-bottom: 4.5rem !important;
        &.nav-img-wrapper {
          display: none !important;
        }
        &:after {
          display: none !important;
        }
        &:last-child a {
          border: 2px solid $white;
          border-radius: 3px;
          padding: 1.9rem;
        }
        &.active {
          a {
            color: $white !important;
            font-weight: bold;
          }
        }
        a {
          font-size: 2.2rem;
          line-height: 1.125;
          letter-spacing: 0.2rem;
          text-align: center;
          text-transform: uppercase;
          color: $white;
          font-weight: bold;
          &:hover {
            color: $white;
            text-decoration: none !important;
          }
        }
        ul {
          border: 0 !important;
          padding-top: 0.9rem;
          & > li {
            display: block;
            border: 0;
            padding: 0 !important;
            a {
              color: $white;
              font-size: 1.2rem;
              line-height: 2.5;
              letter-spacing: 0.2rem;
              text-align: center;
              opacity: 0.6;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
    }
  }
}

.overlay-slidedown {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.4s ease-in-out, visibility 0s 0.4s;
  transition: transform 0.4s ease-in-out, visibility 0s 0.4s;
}

.overlay-slidedown.open {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
}