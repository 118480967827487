.site-footer {
  @media (max-width: 989px) {
    font-size: 13px;
  }
  @media (min-width: 990px) and (max-width: 1279px) {
    font-size: 15px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
  > div {
    padding: em(46) em(46) em(41) !important;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      padding: 0;
      margin: 0;
      @media (max-width: 767px) {
        height: 5.2rem;
      }
      a {
        text-transform: uppercase;
        font-family: $header-sans;
      }
    }
  }
  @media (max-width: 850px) {
    .span6 {
      width: 100% !important;
    }
  }
  nav.footer-nav {
    text-align: left;
    li {
      margin: 0 em(10);
      @media (max-width: 850px) {
        display: block;
        width: 100%;
        text-align: center;
        height: 5.2rem;
        margin: 0;
      }
      &:first-child {
        margin-left: 0;
        a {
          color: $primary;
          border-color: $primary;
          &:hover {
            background: $primary;
          }
        }
      }
      a {
        font-size: em(19);
        font-weight: bold;
        font-family: $header-sans;
        line-height: 1.15;
        letter-spacing: em(3, 19);
        padding: em(15) em(15);
        transition: 0.25s all ease-in-out;
        border: 3px solid $gray;
        border-radius: 3px;
        color: $gray;
        @media (min-width: 768px) {
          position: relative;
          top: em(8, 19);
        }
        &:hover {
          background: $gray;
          color: $white;
        }
        @media (max-width: 850px) {
          width: 100%;
          display: block;
        }
      }
    }
  }
  nav.social-icons {
    @media (max-width: 850px) {
      text-align: center;
      padding-top: em(20);
    }
    @media (min-width: 851px) {
      text-align: right;
    }
    li {
      margin: 0 em(17.5);
      &:last-child {
        margin-right: 0;
      }
      a {
        width: em(48);
        height: em(40.6);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        span {
          display: none;
        }
        &.twitter {
          background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2279204394-svg/assets/images/social-twitter.svg);
        }
        &.linkedin {
          background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2277203951-svg/assets/images/social-linkedin.svg);
        }
        &.google-plus {
          background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2279190749-svg/assets/images/social-googleplus.svg);
        }
        &.youtube {
          background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2279128044-svg/assets/images/social-youtube.svg);
        }
      }
    }
  }
}