.site-header {
  z-index: 10;
  position: relative;
}

body.secondary-line {
  .site-header > .span12 {
    border-bottom: 2px solid $gray;
  }
  .footer-container-wrapper {
    border-top: 2px solid $gray;
  }
}

.site-header > div {
  background-color: $white;
  width: 100%;
  padding: 0 em(25) !important;
  .span3 {
    padding: em(25) 0 !important;
  }
  h1.logo {
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-size: contain !important;
    background-image: url("http://img.jillkonrath.com/hs-fs/hub/110248/file-2264453017-svg/assets/images/jill-konrath-brand.svg");
    @media (max-width: 767px) {
      width: 204px;
      height: 26px;
    }
    @media (min-width: 768px) and (max-width: 1279px) {
      width: 241px;
      height: 31px;
    }
    @media (min-width: 1280px) {
      width: 296px;
      height: 38px;
    }
    span {
      font-size: 0;
      display: none;
    }
  }
  @media (max-width: 767px) {
    .span3 {
      width: 50% !important;
    }
    .span9 {
      width: 50% !important;
      margin-top: -1rem;
    }
  }
  @media (min-width: 768px) and (max-width: 950px) {
    .span9 {
      width: calc(75% - 95px);
    }
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    .span9 {
      margin-top: -0.5rem;
    }
  }
  .main-nav {
    ul {
      margin: 0;
      padding: 0;
      text-align: right;
      list-style-type: none;
      > li {
        display: inline-block;
        position: relative;
        height: 9rem;
        line-height: 9rem;
        margin: 0;
        &.hs-menu-depth-1:last-child a {
          margin-right: 0;
          padding: 1rem;
          color: $primary;
          border: 2px solid $primary;
          border-radius: 3px;
          &:hover {
            background: $primary;
            color: $white;
          }
        }
        &.hs-item-has-children:hover {
          &:after {
            background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2287662829-svg/assets/images/caret-hover.svg);
          }
        }
        &.hs-item-has-children:after {
          content: '';
          display: inline-block;
          width: 7px;
          height: 7px;
          vertical-align: middle;
          background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2285469051-svg/assets/images/caret.svg);
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          position: relative;
          left: -15px;
          top: -2px;
        }
        .hs-menu-children-wrapper {
          display: none;
          opacity: 0;
        }
        &:hover {
          > a {
            color: $secondary;
          }
          //Dropdown menu
          .hs-menu-children-wrapper {
            opacity: 1;
            display: block;
            position: absolute;
            top: 9rem;
            transition: 0.125s all ease-in-out;
            width: 21.5rem;
            background: $dark-gray;
            text-align: left;
            margin: 0;
            padding: 1.7rem;
            > li {
              margin: 0;
              padding: 1.3rem 0;
              text-align: left;
              height: auto;
              line-height: 1.68rem;
              display: block;
              width: 100%;
              &:not(:last-child) {
                border-bottom: 1px solid rgb(86, 89, 90);
              }
              > a {
                color: $white;
                text-transform: uppercase;
                font-size: 1.4rem;
                font-family: $header-sans;
                font-weight: normal;
                line-height: 1.68rem;
                letter-spacing: 0.28rem;
                display: block;
                width: 100%;
                margin: 0;
                &:hover {
                  color: $secondary;
                }
              }
            }
          }
        }
        a {
          font-size: 1.4rem;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 1.07;
          color: $nav-gray;
          margin: 0 1.5rem;
          transition: 0.125s all ease-in-out;
          @media (max-width: 1279px) {
            letter-spacing: 0.1rem;
          }
          @media (min-width: 1280px) {
            letter-spacing: 0.28rem;
          }
          &:hover {
            color: $secondary;
            text-decoration: none;
          }
        }
        @media (max-width: 950px) {
          &:not(.contact) {
            display: none;
          }
        }
        &.contact {
          @media (max-width: 767px) {
            display: none;
          }
        }
        //Contact Jill Link
        &.contact a {
          margin-right: 0;
          padding: 1rem;
          color: $primary;
          border: 2px solid $primary;
          border-radius: 3px;
          &:hover {
            background: $primary;
            color: $white;
          }
        }
      }
    }
  }
  #trigger-overlay {
    border: 0;
    background: transparent;
    @media (max-width: 950px) {
      display: inline-block;
      position: absolute;
      right: 1rem;
      top: 2.2rem;
    }
    @media (min-width: 951px) {
      display: none;
    }
    &:before {
      content: '';
      width: 3.2rem;
      height: 1.6rem;
      background-image: url(http://img.jillkonrath.com/hs-fs/hub/110248/file-2279936624-svg/assets/images/hamburger.svg);
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: middle;
      @media (max-width: 767px) {
        width: 2.1rem;
      }
    }
    span {
      color: $dark-gray;
      display: inline-block;
      font-size: 1.4rem;
      letter-spacing: 0.1rem;
      line-height: 1.6rem;
      text-transform: uppercase;
      font-family: $header-sans;
      font-weight: bold;
      vertical-align: middle;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.hs-menu-children-wrapper:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.655rem 0.655rem 0.655rem;
  border-color: transparent transparent $dark-gray transparent;
  content: '';
  display: block;
  position: absolute;
  top: -0.6rem;
}

.site-header.affix {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}
.active > a,
.active:hover > a,
.active > a:hover {color:$primary !important;}