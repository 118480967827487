.not-found {
  &__nest {
    display: flex;
    position: relative;
    width: 100%;
    background-color: #FDFDFD;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    @media (min-width: 992px) {
      min-height: 90vh;
      align-items: stretch;
    }
  }
  &__page {

  }
  &__content,
  &__image {
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  &__content {
    left: 0;
    padding-top: em(57) !important;
    z-index: 3;
    @media (max-width: 767px) {
      font-size: 12px;
    }
    @media (min-width: 992px) {
      width: 41.3% !important;
    }
    @media (max-width: 1199px) {
      padding-left: em(35);
      padding-right: em(35);
    }
    @media (min-width: 1200px) {
      padding-left: em(136) !important;
    }
    > span {
      display: block;
    }
    p,
    a {
      font-family: $header-sans;
    }
    p {
      color: #919292;
      font-size: em(26);
      font-weight: 600;
      line-height: (33.8 / 26);
      margin-bottom: em(42, 26);
    }
    ul {
      color: #919292;
      margin: 0 0 em(39);
      list-style-type: disc;
      padding-left: 1.25em;
      li {
        margin-bottom: em(21);
      }
      a {
        color: #269ccc;
        font-size: em(26);
        font-weight: 400;
        line-height: (26 / 26);
      }
    }
  }
  &__image {
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 991px) {
      height: 0;
      padding-bottom: 78.1%;
      background-position: center bottom;
      margin-top: -3em;
    }
    @media (min-width: 992px) {
      width: 66.6% !important;
      background-position: right bottom;
    }
  }
}